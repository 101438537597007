<script setup lang="ts">
/**
 * default layout для домашней страницы приложения
 * с серым фоном
 */
useHead({
  bodyAttrs: {
    class: ['bg-secondary'],
  },
});
</script>

<template>
  <div class="page-layout">
    <slot />
  </div>
</template>

<style lang="scss"></style>
